import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { useCallback, useEffect, useState } from "react";
import { app } from "~/core/initFirebase";

export default function useLogEvent() {
  const [analytics, setAnalytics] = useState<Analytics>();

  useEffect(() => {
    setAnalytics(getAnalytics(app));
  }, []);

  const log = useCallback(
    (
      eventName: Parameters<typeof logEvent>[1],
      eventParams?: Parameters<typeof logEvent>[2],
      options?: Parameters<typeof logEvent>[3]
    ) => {
      if (analytics) {
        return logEvent(analytics, eventName, eventParams, options);
      }
      console.warn(
        "Analytics is not initialized. This event will not be logged.",
        eventName,
        eventParams
      );
    },
    [analytics]
  );

  return log;
}
