import {
  Box,
  Container,
  Stack,
  Text,
  Link,
  Icon,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { ROUTES } from "~/core/constants";

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.800")}
      color={useColorModeValue("gray.700", "gray.200")}
      fontSize="sm"
      fontWeight={200}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Stack direction={"row"} spacing={6}>
          <NextLink href={ROUTES.aboutUs} passHref>
            <Link>About Us</Link>
          </NextLink>
          <Link href="https://hype.day/blog" isExternal>
            Blog
          </Link>
          <Link
            href="https://hype-day.notion.site/Privacy-Notice-c7e648566d564eb4831ef741768bec3b"
            isExternal
          >
            Privacy Policy
          </Link>
          <Link
            href="https://hype-day.notion.site/Terms-of-Service-fb34231cd00143fe891b7ea9136e74d3"
            isExternal
          >
            Terms of Service
          </Link>
        </Stack>
        <Stack direction={"row"} spacing={4}>
          <IconButton
            as={Link}
            bg={"blue.400"}
            rounded="full"
            color={"white"}
            size={"sm"}
            href={"https://twitter.com/hypedotday"}
            target={"_blank"}
            fontWeight={400}
            _hover={{ bg: "blue.500" }}
            display={{ base: "flex", md: "flex" }}
            icon={<Icon as={FaTwitter} />}
            aria-label="Hype.Day Twitter Account"
          />

          <IconButton
            as={Link}
            colorScheme={"discord"}
            rounded="full"
            color={"white"}
            size={"sm"}
            href={"https://discord.gg/BuEJE2Fec5"}
            target={"_blank"}
            fontWeight={400}
            display={{ base: "flex", md: "flex" }}
            icon={<Icon as={FaDiscord} />}
            aria-label="Hype.Day Discord Server"
          />
        </Stack>
        <Text>© 2022 Hype.day. All rights reserved</Text>
      </Container>
    </Box>
  );
}
