import { getApp, getApps, initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

let firebaseConfig = {
  apiKey: "AIzaSyDQRXxXYN0sE70TXYQks5j3Kg5cGRQFWds",
  authDomain: "preparty-f79e2.firebaseapp.com",
  projectId: "preparty-f79e2",
  storageBucket: "preparty-f79e2.appspot.com",
  messagingSenderId: "390387011977",
  appId: "1:390387011977:web:fce5ae5a72f3c3ef8c9158",
  measurementId: "G-V95JF6CH5H",
};

if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
  firebaseConfig = {
    apiKey: "AIzaSyDxyuuENH42Vwc6oB8WVB8Cmvxo7wWZ_Bg",
    authDomain: "hype-testing.firebaseapp.com",
    projectId: "hype-testing",
    storageBucket: "hype-testing.appspot.com",
    messagingSenderId: "683008870565",
    appId: "1:683008870565:web:c3b11681cdf5d2e65aa54c",
    measurementId: "G-TSSZNNL3MH",
  };
}

export const app = getApps().length ? getApp() : initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const localhostFunctionsForTesting = () => {
  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, "localhost", 5001);
  return functions;
};

auth.setPersistence(browserLocalPersistence).catch();
