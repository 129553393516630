import {
  Box,
  Flex,
  Text,
  Button,
  useColorModeValue,
  useColorMode,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
  Collapse,
  Stack,
  Link,
  ColorMode,
} from "@chakra-ui/react";
import { ROUTES } from "~/core/constants";
import {
  IoChevronDownOutline,
  IoLogOutOutline,
  IoMoonOutline,
  IoPersonOutline,
  IoSunnyOutline,
} from "react-icons/io5";
import NextLink from "next/link";
import { formatUid } from "~/core/helpers";
import GetStartedButton from "./GetStartedButton";
import { FaBars, FaDiscord, FaTimes, FaTwitter } from "react-icons/fa";
import type { MouseEventHandler } from "react";
import useAuthContext from "~/context/auth.context";

export default function Navbar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onToggle } = useDisclosure();
  const { user } = useAuthContext();

  return (
    <Box mt={4}>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH="60px"
        py={2}
        px={4}
        align="center"
        maxW={1200}
        mx={"auto"}
      >
        <Flex mr={2} display={{ base: "flex", xl: "none" }}>
          <IconButton
            onClick={onToggle}
            icon={<Icon as={isOpen ? FaTimes : FaBars} />}
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>

        <Flex flex={1}>
          <NextLink href="/" passHref>
            <Link _hover={{ textDecoration: "none" }}>
              <Text
                minW={"80px"}
                color={useColorModeValue("gray.700", "gray.200")}
                fontFamily="'Bungee Shade', cursive"
                fontSize={{ base: "24px", md: "32px", lg: "40px" }}
              >
                HYPE.DAY
              </Text>
            </Link>
          </NextLink>

          <Flex display={{ base: "none", xl: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Flex flex={{ base: 1, md: 0 }} justify="flex-end" direction="row">
          <IconButton
            as={Link}
            bg={"blue.400"}
            rounded="full"
            color={"white"}
            size={"sm"}
            href={"https://twitter.com/hypedotday"}
            target={"_blank"}
            mr={4}
            px={4}
            fontWeight={400}
            _hover={{ bg: "blue.500" }}
            display={{ base: "none", md: "flex" }}
            icon={<Icon as={FaTwitter} />}
            aria-label="Hype.Day Twitter Account"
          />

          <IconButton
            as={Link}
            colorScheme={"discord"}
            rounded="full"
            color={"white"}
            size={"sm"}
            href={"https://discord.gg/BuEJE2Fec5"}
            target={"_blank"}
            mr={4}
            px={4}
            fontWeight={400}
            display={{ base: "none", md: "flex" }}
            icon={<Icon as={FaDiscord} />}
            aria-label="Hype.Day Discord Server"
          />

          {user ? <LoggedInButtons /> : <GetStartedButton />}

          <Button
            variant="ghost"
            onClick={toggleColorMode}
            ml={2}
            size="sm"
            display={{ base: "none", xl: "initial" }}
          >
            <Icon as={colorMode === "light" ? IoMoonOutline : IoSunnyOutline} />
          </Button>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav
          onToggle={onToggle}
          colorMode={colorMode}
          toggleColorMode={toggleColorMode}
          isLoggedIn={!!user}
        />
      </Collapse>
    </Box>
  );
}

function LoggedInButtons() {
  const { logout, user } = useAuthContext();

  return (
    <>
      <NextLink href={ROUTES.creatorDashboard} passHref>
        <Button
          bg={"green.400"}
          color={"gray.50"}
          rounded={"full"}
          px={4}
          _hover={{
            bg: "green.500",
          }}
          fontWeight={"400"}
          size="sm"
          mr={4}
          display={{ base: "none", xl: "flex" }}
        >
          Creator Dashboard
        </Button>
      </NextLink>

      <Menu>
        <MenuButton
          variant="ghost"
          as={Button}
          leftIcon={<Icon as={IoPersonOutline} />}
          rightIcon={<Icon as={IoChevronDownOutline} />}
          cursor="pointer"
          minW={0}
          size={"sm"}
          rounded="full"
          borderWidth={1}
          borderColor={"gray.200"}
          fontWeight={useColorModeValue(500, 300)}
        >
          <Text display={{ base: "none", sm: "flex" }}>
            {user?.uid && formatUid(user.uid)}
          </Text>
        </MenuButton>
        <MenuList>
          <NextLink href={ROUTES.profile} passHref>
            <MenuItem>
              <Icon as={IoPersonOutline} mr={2} />
              <Text mr={2}>Profile</Text>
              <Text noOfLines={1} display={{ base: "flex", sm: "none" }}>
                ({user?.uid && formatUid(user.uid)})
              </Text>
            </MenuItem>
          </NextLink>
          <MenuItem onClick={logout}>
            <Icon as={IoLogOutOutline} mr={2} /> Log Out
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");

  const { user } = useAuthContext();

  return (
    <Flex alignItems={"center"}>
      <NextLink href={ROUTES.publicProjects} passHref>
        <Link
          fontWeight="400"
          minW={0}
          fontSize={"sm"}
          color={linkColor}
          mr={4}
          _hover={{
            textDecoration: "none",
            color: linkHoverColor,
          }}
        >
          Explore
        </Link>
      </NextLink>
      {user && (
        <NextLink href={ROUTES.joinedProjects} passHref>
          <Link
            fontWeight="400"
            minW={0}
            fontSize={"sm"}
            color={linkColor}
            mr={4}
            _hover={{
              textDecoration: "none",
              color: linkHoverColor,
            }}
          >
            My Entries
          </Link>
        </NextLink>
      )}
    </Flex>
  );
};

const MobileNav = ({
  onToggle,
  isLoggedIn,
  toggleColorMode,
  colorMode,
}: {
  onToggle: MouseEventHandler<HTMLDivElement>;
  isLoggedIn: boolean;
  toggleColorMode: () => void;
  colorMode: ColorMode;
}) => {
  const hoverBg = useColorModeValue("gray.100", "gray.900");
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ xl: "none" }}
      borderWidth={1}
    >
      {NAV_ITEMS.map((navItem) => {
        const item = (
          <MobileNavItem
            key={navItem.label}
            {...{ ...navItem, onToggle: onToggle }}
          />
        );

        return navItem.requiresAuth ? (isLoggedIn ? item : null) : item;
      })}

      <Flex
        _hover={{
          backgroundColor: hoverBg,
          textDecoration: "none",
        }}
        py={1}
        onClick={toggleColorMode}
        as={Link}
      >
        <Button
          as={Text}
          variant="ghost"
          size="sm"
          ml={12}
          fontWeight={300}
          px={0}
          background={"transparent"}
          _hover={{
            backgroundColor: "transparent",
          }}
          _active={{
            backgroundColor: "transparent",
          }}
          leftIcon={
            colorMode === "light" ? (
              <IoMoonOutline></IoMoonOutline>
            ) : (
              <IoSunnyOutline></IoSunnyOutline>
            )
          }
        >
          {colorMode === "light" ? "Light Theme" : "Dark Theme"}
        </Button>
      </Flex>
    </Stack>
  );
};

const MobileNavItem = ({ label, to, href, onToggle }: NavItem) => {
  const hoverBg = useColorModeValue("gray.100", "gray.900");
  return (
    <Stack spacing={4}>
      {to && (
        <NextLink href={to} passHref>
          <Flex
            py={2}
            as={Link}
            justify="space-between"
            align="center"
            _hover={{
              textDecoration: "none",
              backgroundColor: hoverBg,
            }}
            onClick={onToggle}
          >
            <Text ml={12} fontWeight={200}>
              {label}
            </Text>
          </Flex>
        </NextLink>
      )}

      {href && (
        <Flex
          py={2}
          as={Link}
          href={href}
          target={"_blank"}
          justify="space-between"
          align="center"
          _hover={{
            textDecoration: "none",
            backgroundColor: hoverBg,
          }}
          onClick={onToggle}
        >
          <Text ml={12} fontWeight={200}>
            {label}
          </Text>
        </Flex>
      )}
    </Stack>
  );
};

interface NavItem {
  label: string;
  to?: string;
  href?: string;
  requiresAuth?: boolean;
  onToggle?: MouseEventHandler<HTMLDivElement>;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Explore",
    to: ROUTES.publicProjects,
  },
  {
    label: "My Entries",
    to: ROUTES.joinedProjects,
    requiresAuth: true,
  },
  {
    label: "Creator Dashboard",
    to: ROUTES.creatorDashboard,
    requiresAuth: true,
  },
  {
    label: "Discord",
    href: "https://discord.gg/BuEJE2Fec5",
  },
  {
    label: "Twitter",
    href: "https://twitter.com/hypedotday",
  },
];
