import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface NotificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  title?: string;
  successFunction: () => void;
}

export default function NotificationModal({
  isOpen,
  onClose,
  message,
  title,
  successFunction,
}: NotificationModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title ? title : ""}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>{message}</ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              onClose();
              successFunction();
            }}
          >
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
