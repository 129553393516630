import type { Timestamp } from "firebase/firestore";
import html2canvas from "html2canvas";
import Linkify from "linkify-react";
import { toast } from "./toast";
import { SupportedChains, UserDocument } from "./types";

export function formatUid(uid: string) {
  return `${uid.slice(0, 5)}...`;
}

export function validateDiscordServerLink(link: string): boolean {
  if (!link) return false;
  if (!link.startsWith("https://discord.gg/")) return false;
  if (link.length < 20) return false;

  return true;
}

// a valid Tweet link looks like https://twitter.com/rod_mallo/status/1526893405095305221
export function validateRetweetUrl(link: string): string {
  if (!link) return "";
  if (!link.startsWith("https://twitter.com/")) return "";
  if (!link.includes("status", 21)) return "";
  if (link.length < 30) return "";

  const tweetId = link.split("status/")[1].split("?")[0];
  if (tweetId.length < 1) return "";

  return tweetId;
}
export const getTweetIdFromUrl = validateRetweetUrl;

export function tsOrStringToInputString(
  ts?: Timestamp | string | Date
): string {
  if (!ts) return "";

  const date = ts instanceof Date ? ts : tsOrStringToDate(ts);
  const dd = String(date.getUTCDate()).padStart(2, "0");
  const MM = String(date.getUTCMonth() + 1).padStart(2, "0");
  const yyyy = date.getUTCFullYear();
  const hh = String(date.getUTCHours()).padStart(2, "0");
  const mm = String(date.getUTCMinutes()).padStart(2, "0");

  return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
}

export const twitterUsernameRegex = new RegExp(/^[A-Za-z0-9_]{1,15}$/);
export const twitterUsernameListRegex = new RegExp(
  /^([A-Za-z0-9_]{1,15})(\s?,\s*?[A-Za-z0-9_]{1,15})*$/
);

export const editorConfig = {
  min_height: 250,
  resize: true,
  menubar: false,
  inline: true,
  statusbar: false,
  plugins: "image table link autoresize lists",
  toolbar:
    "undo redo | casechange blocks | bold italic underline forecolor backcolor | " +
    "alignleft aligncenter alignright alignjustify | " +
    "bullist numlist checklist outdent indent | link image | table",
};

export const editorApiKey = "hf98dz4e13dwmk7hr7x2zk28e70tnja319pnwpt0o9tyk7sg";

export const linkifyOptions = {
  attributes: {
    target: "_blank",
    style: { textDecoration: "underline" },
  },
};

export function showError(error: any, showGenericMsg?: boolean) {
  console.log(error);
  const title = "Oops!";
  const msg = showGenericMsg
    ? "Something went wrong. Please try reloading the page."
    : error.name === "AxiosError"
    ? error.response.data?.error?.message
    : error.message ??
      "Something went wrong. We're looking into it. You can also try again.";

  toast({
    title,
    description: <Linkify options={linkifyOptions}>{msg}</Linkify>,
    status: "error",
  });
}

export function tsOrStringToDate(date: Timestamp | string): Date {
  if (typeof date === "string") {
    return new Date(date);
  }

  return date.toDate();
}

export function pickWalletForProject(
  projectChain: SupportedChains,
  wallets: UserDocument["wallets"]
) {
  if (!projectChain || !wallets) return undefined;

  return wallets?.[projectChain]?.[0];
}

export function copyToClipboard(text: string) {
  const sampleTextarea = document.createElement("textarea");
  document.body.appendChild(sampleTextarea);
  sampleTextarea.value = text; //save main text in it
  sampleTextarea.select(); //select textarea contenrs
  document.execCommand("copy");
  document.body.removeChild(sampleTextarea);
}

export const CHAINS = {
  mainnet: "Ethereum (ETH)",
  polygon: "Polygon (MATIC)",
  fantom: "Fantom (FTM)",
  avalanche: "Avalanche (AVAX)",
  optimism: "Optimism (ETH)",
  arbitrum: "Arbitrum (ETH)",
  binancesmartchainmainnet: "Binance Smart Chain (BNB)",
  goerli: "Goerli (GOR)",
  mumbai: "Mumbai (MATIC)",
  fantomtestnet: "Fantom Testnet (FTM)",
  avalanchefujitestnet: "Avalanche Fuji Testnet (AVAX)",
  optimismgoerli: "Optimism Goerli (ETH)",
  arbitrumgoerli: "Arbitrum Goerli (AGOR)",
  binancesmartchaintestnet: "Binance Smart Chain Testnet (TBNB)",
};

export const CHAIN_LIST = [
  "mainnet",
  "polygon",
  "fantom",
  "avalanche",
  "optimism",
  "arbitrum",
  "binancesmartchainmainnet",
  "goerli",
  "mumbai",
  "fantomtestnet",
  "avalanchefujitestnet",
  "optimismgoerli",
  "arbitrumgoerli",
  "binancesmartchaintestnet",
];

export const uuidv4 = function () {
  return "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx".replace(/[x]/g, (c: any) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export async function getImageDimensions(
  image: File
): Promise<{ width: number; height: number }> {
  return new Promise<{ width: number; height: number }>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      async () => {
        const img = new Image();
        img.onload = function () {
          resolve({ width: img.width, height: img.height });
        };
        img.src = reader.result as string;
      },
      false
    );
    reader.readAsDataURL(image);
  });
}

export async function getImageBase64(image: File): Promise<string> {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      async () => {
        resolve(reader.result as string);
      },
      false
    );
    reader.readAsDataURL(image);
  });
}

export function urltoFile(
  url: string,
  filename: string,
  mimeType: "image/png"
) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export async function getHtmlTemplateImageBase64(
  element: HTMLElement
): Promise<string> {
  if (!element) {
    return "";
  }

  const canvas = await html2canvas(element, {
    scale: 3,
    backgroundColor: null,
  });
  return canvas.toDataURL("image/png", 1.0);
}

export function createOpenseaLink(contractAddress: string, tokenId: string) {
  const base =
    process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "https://opensea.io/assets/matic/"
      : "https://testnets.opensea.io/assets/mumbai/";

  return `${base}${contractAddress}/${tokenId}`;
}
