import { useRef, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import useAuthContext from "~/context/auth.context";
import api from "~/core/api";
import { showError } from "~/core/helpers";
import { toast } from "~/core/toast";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "~/core/initFirebase";

interface EmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  mode?: "login" | "add";
}

export default function EmailModal({
  isOpen,
  onClose,
  mode = "login",
}: EmailModalProps) {
  const { userData, getAllData } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<"one" | "two">("one");
  const [otp, setOtp] = useState<string>("");
  const emailRef = useRef();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const email = e.currentTarget?.email?.value;
    const emailPermission = e.currentTarget?.emailPermission?.checked;

    if (!email) return;

    if (mode === "add" && userData?.email === email) {
      return onClose();
    }

    emailRef.current = email;

    try {
      setIsLoading(true);
      await (mode === "login"
        ? api.loginWithEmail(email, emailPermission)
        : api.addNewEmail(email, emailPermission));

      setStep("two");
      toast({
        title: "Success",
        description:
          "Please check your inbox and spam folder to verify your email address.",
      });
    } catch (err: any) {
      console.error(err);
      showError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOTPSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!emailRef.current || !otp || otp.length !== 6) return;

    try {
      setIsLoading(true);
      const token = await (mode === "login"
        ? api.verifyEmail(emailRef.current, otp)
        : api.verifyNewEmail(emailRef.current, otp));

      if (mode === "login") {
        await signInWithCustomToken(auth, token);
      } else {
        getAllData();
      }

      setStep("one");
      onClose();
      toast({
        title: "Success",
        description:
          mode === "login"
            ? "Successfully logged in with email."
            : "Your email has been verified.",
      });
    } catch (err: any) {
      console.error(err);
      showError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={step === "one" ? handleSubmit : handleOTPSubmit}>
          <ModalHeader>
            {step === "one"
              ? "Enter Your Email Address"
              : "Enter Verification Code"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {step === "one" ? (
              <>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    id="email"
                    disabled={isLoading}
                    placeholder="Your Email Address"
                    type="email"
                    defaultValue={userData?.email ?? ""}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <Checkbox id="emailPermission" defaultChecked={true}>
                    Subscribe to Our Newsletter
                  </Checkbox>
                </FormControl>
              </>
            ) : (
              <FormControl>
                <FormLabel>You can copy and paste from the email</FormLabel>
                <HStack>
                  <PinInput otp onComplete={setOtp}>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </FormControl>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              colorScheme="blue"
              mr={4}
              isLoading={isLoading}
            >
              {step === "one" ? "Request Verification Code" : "Verify"}
            </Button>
            <Button onClick={step === "one" ? onClose : () => setStep("one")}>
              {step === "one" ? "Cancel" : "Go Back"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
