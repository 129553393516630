import { mode } from "@chakra-ui/theme-tools";
import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const styles = {
  global: (props: any) => ({
    body: {
      color: mode("gray.800", "gray.100")(props),
      bg: mode("white", "#1A202C")(props),
      fontFamily: `'Lexend', sans-serif !important`,
    },
  }),
};

const components = {
  Navbar: {
    // setup light/dark mode component defaults
    baseStyle: (props: any) => ({
      dialog: {
        bg: mode("white", "#1A202C")(props),
      },
    }),
  },
};

const config: ThemeConfig = {
  initialColorMode: "dark",
};

const theme = extendTheme({
  config,
  colors: {
    discord: {
      100: "#5865F2",
      200: "#5865F2",
      300: "#5865F2",
      400: "#5865F2",
      500: "#5865F2",
      600: "#5865F2",
      700: "#5865F2",
      800: "#5865F2",
      900: "#5865F2",
    },
  },
  semanticTokens: {
    colors: {
      descText: {
        default: "gray.800",
        _dark: "gray.300",
      },
      baseBg: {
        default: "white",
        _dark: "#1A202C",
      },
      boxBorder: {
        default: "gray.500",
        _dark: "gray.200",
      },
    },
  },
  components,
  styles,
});
export default theme;

/*
font-family: 'Bungee Inline', cursive;
font-family: 'Bungee Shade', cursive;
font-family: 'Lexend', sans-serif;
font-family: 'Lexend Deca', sans-serif;
font-family: 'Work Sans', sans-serif;

*/
