import { useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Textarea,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { VscFeedback } from "react-icons/vsc";
import api from "~/core/api";
import { showError } from "~/core/helpers";
import useAuthContext from "~/context/auth.context";
import { toast } from "~/core/toast";

export default function FeedbackButton() {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuthContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!e.currentTarget.message.value) return;
    let pageUrl = "";
    if (window?.location?.href) {
      pageUrl = window.location.href;
    }
    const userId = user?.uid ?? "";
    setIsLoading(true);
    api
      .feedback({
        message: e.currentTarget.message.value,
        contactInfo: e.currentTarget.contactInfo.value + " (" + pageUrl + ")",
        userId: userId,
      })
      .then(() => {
        toast({ title: "Feedback sent!", position: "bottom-right" });
        (e.target as HTMLFormElement).reset();
      })
      .catch((err) => showError(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <Popover placement="top-start" gutter={16}>
      <PopoverTrigger>
        <Button
          aria-label="Feedback"
          leftIcon={<VscFeedback />}
          position="fixed"
          backgroundColor={useColorModeValue("gray.100", "gray.900")}
          bottom={4}
          right={4}
          fontWeight={"normal"}
        >
          Feedback
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton mt={1} />
        <PopoverHeader>Give Feedback</PopoverHeader>
        <PopoverBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="flex-start">
              <FormControl>
                <FormLabel htmlFor="contactInfo">Twitter or Email</FormLabel>
                <Input id="contactInfo" type="text" />
                <FormHelperText>
                  If you&apos;d like us to get back to you, please provide your
                  email address (preferred) or Twitter username.
                </FormHelperText>
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="message">Message</FormLabel>
                <Textarea id="message" resize={"none"} rows={5} />
              </FormControl>
              <Button
                type="submit"
                colorScheme="blue"
                width={"full"}
                isLoading={isLoading}
              >
                Send
              </Button>
            </VStack>
          </form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
