import type { CompositeSignature } from "@onflow/fcl";
import axios from "axios";
import { IProjectEditRegistrationFlowModel } from "./models/project-edit-registration-flow.model";
import { API_URL } from "./constants";
import { IProjectCreateModel } from "./models/project-create.model";
import { IProjectEditSignupAccessModel } from "./models/project-edit-signup-access.model";
import { IProjectInfoEditModel } from "./models/project-info-edit.model";
import FileDownload from "js-file-download";
import { AppDocument, SupportedChains } from "./types";
import { IAppProjectCreateModel } from "./models/app-project-create.model";
import { ITwitterCampaignEditModel } from "./models/twitter-campaign-edit.model";

const instance = axios.create({ baseURL: `${API_URL}/api` });

function setToken(token: string) {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

function getNonce() {
  return instance.get("/auth/nonce");
}

function loginWithEmail(email: string, emailPermission: boolean) {
  return instance.post("/auth/login-with-email", { email, emailPermission });
}

function verifyEmail(email: string, otp: string) {
  return instance
    .post("/auth/verify-email", { email, otp })
    .then((res) => res.data?.token);
}

function addNewEmail(email: string, emailPermission: boolean) {
  return instance.post("/auth/email/add", { email, emailPermission });
}

function verifyNewEmail(email: string, otp: string) {
  return instance.post("/auth/email/verify", { email, otp });
}

function loginWithDiscord() {
  return instance.post("/auth/login-with-discord").then((res) => res.data.url);
}

function login(data: { chain: SupportedChains; address: string }) {
  return instance
    .post("/auth/login", data)
    .then((res: { data: { nonce: string } }) => res.data);
}

function verify(data: {
  chain: SupportedChains;
  address: string;
  signature?: string;
  signatures?: CompositeSignature[];
  nonce?: string;
}) {
  return instance
    .post("/auth/verify", data)
    .then((res: { data: { token: string } }) => res.data);
}

function addWallet(data: { chain: SupportedChains; address: string }) {
  return instance
    .post("/auth/wallets/add", data)
    .then((res: { data: { nonce: string } }) => res.data);
}

function verifyWallet(data: {
  chain: SupportedChains;
  address: string;
  signature?: string;
  signatures?: CompositeSignature[];
  nonce?: string;
}) {
  return instance.post("/auth/wallets/verify", data);
}

function requestPhoneVerification(phone: string) {
  return instance.post("/auth/request-phone-verification", {
    phone,
  });
}

function confirmPhoneVerification(code: string) {
  return instance.post("/auth/confirm-phone-verification", {
    code,
  });
}

function createProject(model: IProjectCreateModel) {
  return instance
    .post("/projects", model)
    .then((res: { data: { id: string } }) => res.data);
}

function createAppProject(model: IAppProjectCreateModel) {
  return instance
    .post("/projects/create-app-project", model)
    .then((res: { data: { id: string } }) => res.data);
}

function updateProjectInfo(projectId: string, model: IProjectInfoEditModel) {
  return instance.patch(`/projects/${projectId}/edit-info`, model).then();
}

function editProjectRegistrationFlow(
  projectId: string,
  model: IProjectEditRegistrationFlowModel
) {
  return instance
    .patch(`/projects/${projectId}/registration-flow`, model)
    .then((res) => res.status);
}

function editProjectSignupAccess(
  projectId: string,
  model: IProjectEditSignupAccessModel
) {
  return instance
    .patch(`/projects/${projectId}/signup-access`, model)
    .then((res) => res.status);
}

function getProjectApiAccessUrl(projectId: string) {
  return instance
    .get(`/projects/${projectId}/api-access`)
    .then((res: { data: { url: string | null } }) => res.data.url);
}

function createProjectApiAccessUrl(projectId: string) {
  return instance
    .post(`/projects/${projectId}/api-access`)
    .then((res) => res.data.url as string);
}

function createRaffle(
  projectId: string,
  payload: { winnerCount: number; waitlistCount: number }
) {
  return instance.post(`/projects/${projectId}/raffle`, payload);
}

function DownloadRaffleList(projectId: string, list: "winners" | "waitlist") {
  return instance.get(`/projects/${projectId}/raffle/${list}`);
}

function deleteProject(projectId: string) {
  return instance.delete(`/projects/${projectId}`);
}

function exportProjectUsers(projectId: string) {
  return instance
    .get(`/projects/${projectId}/users`, { responseType: "blob" })
    .then((res) => FileDownload(res.data, "users.csv"));
}

async function duplicateProject(
  projectId: string
): Promise<{ data: { id: string } }> {
  return await instance.post(`/projects/${projectId}/duplicate`, {});
}

function unregister(projectId: string, userId: string) {
  return instance.delete(`/projects/${projectId}/users/${userId}`);
}

function feedback(data: {
  message: string;
  contactInfo?: string;
  userId?: string;
}) {
  return instance.post("/feedback", data);
}

function signupNewsletter(email: string) {
  return instance.post("/newsletter", { email });
}

function createApp(displayName: string) {
  return instance
    .post("/apps", { displayName })
    .then((res: { data: { id: string } }) => res.data);
}

function getApps() {
  return instance
    .get("/apps", {})
    .then((res: { data: Array<AppDocument> }) => res.data);
}

function getApp(id: string) {
  return instance
    .get("/apps/" + id, {})
    .then((res: { data: AppDocument }) => res.data);
}

function addAppAdmin(id: string, uid: string) {
  return instance
    .post(`/apps/${id}/addAdmin`, {
      adminId: uid,
    })
    .then((res: { data: AppDocument }) => res.data);
}

function deleteAppAdmin(id: string, uid: string) {
  return instance
    .post(`/apps/${id}/deleteAdmin`, {
      adminId: uid,
    })
    .then((res: { data: AppDocument }) => res.data);
}

function addAppProject(id: string, projectId: string) {
  return instance
    .post(`/apps/${id}/addProject`, {
      projectId,
    })
    .then((res: { data: AppDocument }) => res.data);
}

function deleteAppProject(id: string, projectId: string) {
  return instance
    .post(`/apps/${id}/deleteProject`, {
      projectId,
    })
    .then((res: { data: AppDocument }) => res.data);
}

function setAppPublicKey(id: string, publicKey: string | undefined) {
  return instance
    .post(`/apps/${id}/setPublicKey`, {
      publicKey,
    })
    .then((res: { data: AppDocument }) => res.data);
}

function requestEmbed(email: string) {
  return instance.post("/embed", { email });
}

function sendValentinesCard(email: string) {
  return instance.post("/valentines/send-card", { email });
}

function createTwitterCampaign(name: string) {
  return instance
    .post("/twitter-campaigns", { name })
    .then((res: { data: { campaignId: string } }) => res.data);
}

function deleteTwitterCampaign(campaignId: string) {
  return instance.delete(`/twitter-campaigns/${campaignId}`);
}

function editTwitterCampaign(
  campaignId: string,
  model: ITwitterCampaignEditModel
) {
  return instance
    .patch(`/twitter-campaigns/${campaignId}`, model)
    .then((res) => res.status);
}

function exportTwitterCampaignUsers(campaignId: string) {
  return instance
    .get(`/twitter-campaigns/${campaignId}/users`, { responseType: "blob" })
    .then((res) => FileDownload(res.data, "users.csv"));
}

function twitterCampaignRequestProcess(campaignId: string) {
  return instance.put(`/twitter-campaigns/${campaignId}/request-process`);
}

async function mintAndSendCard(model: {
  from: string;
  sendAnonymously: boolean;
  message: string;
  imagePath: string;
  recipients: Array<string>;
  sendAt: string | undefined;
  refId: string | null;
}) {
  return instance.post<{ paymentLink: string | null }>(`/ecards`, model);
}
async function transferCard(id: string, to: string) {
  return instance.post<{ paymentLink: string | null }>(
    `/ecards/${id}/transfer`,
    { to }
  );
}

async function incrementEcardViewCount(id: string) {
  return instance.post(`/ecards/${id}/views`);
}

const api = {
  setToken,
  getNonce,
  loginWithEmail,
  verifyEmail,
  addNewEmail,
  verifyNewEmail,
  loginWithDiscord,
  login,
  addWallet,
  verifyWallet,
  verify,
  requestPhoneVerification,
  confirmPhoneVerification,
  createProject,
  createAppProject,
  updateProjectInfo,
  editProjectRegistrationFlow,
  editProjectSignupAccess,
  getProjectApiAccessUrl,
  createProjectApiAccessUrl,
  createRaffle,
  DownloadRaffleList,
  deleteProject,
  exportProjectUsers,
  unregister,
  feedback,
  signupNewsletter,
  createApp,
  requestEmbed,
  sendValentinesCard,
  getApps,
  getApp,
  addAppAdmin,
  deleteAppAdmin,
  addAppProject,
  deleteAppProject,
  setAppPublicKey,
  duplicateProject,
  createTwitterCampaign,
  deleteTwitterCampaign,
  editTwitterCampaign,
  exportTwitterCampaignUsers,
  twitterCampaignRequestProcess,
  mintAndSendCard,
  transferCard,
  incrementEcardViewCount,
};

export default api;
