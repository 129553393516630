import type { AppProps } from "next/app";
import Head from "next/head";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "~/core/theme";
import Layout from "~/components/Layout";
import { AuthProvider } from "~/context/auth.context";
import "../style.scss";
import Script from "next/script";
import { CAPTCHA_KEY } from "~/core/constants";

const SITE_TITLE =
  process.env.NEXT_PUBLIC_SITE_TITLE ||
  "Build community for your Web3 and NFT campaigns on Solana, Flow and EVM";

function addWebsiteJsonLd() {
  return {
    __html: `{
        "@context": "https://schema.org/",
        "@graph": [
          {
            "@type": "WebSite",
            "@id": "https://hype.day/#website",
            "url": "https://hype.day/",
            "name": "NFT access list for Solana, Flow, Avalanche, Polygon, Klaytn and Ethereum",
            "description": "Build high-quality access lists for your Solana, Flow, Avalanche, Polygon, Klaytn and Ethereum NFT projects, web3 giveaways, raffles; all free.",
            "sameAs": "https://twitter.com/hypedotday",
            "inLanguage": "en-US"
          },
          {
            "@type": "Organization",
            "@id": "https://hype.day/aboutus",
            "url": "https://hype.day/aboutus",
            "name": "HYPE.DAY",
            "description": "Build high-quality access lists for your Solana, Flow, Avalanche, Polygon, Klaytn and Ethereum NFT projects, web3 giveaways, raffles; all free.",
            "sameAs": "https://twitter.com/hypedotday",
            "inLanguage": "en-US"
          },
          {
            "@type": "WebPage",
            "@id": "https://hype.day/#webpage",
            "url": "https://hype.day/",
            "name": "NFT access list for Solana, Flow, Avalanche, Polygon, Klaytn and Ethereum",
            "description": "Build high-quality access lists for your Solana, Flow, Avalanche, Polygon, Klaytn and Ethereum NFT projects, web3 giveaways, raffles; all free.",
            "inLanguage": "en-US"
          },
          {
            "@type": "BreadcrumbList",
            "@id": "https://hype.day/#breadcrumblist",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "item": {
                  "@id": "https://hype.day/#home",
                  "name": "Home"
                }
              },
              {
                "@type": "ListItem",
                "position": 2,
                "item": {
                  "@id": "https://hype.day/public-projects",
                  "name": "Public Campaigns"
                }
              },
              {
                "@type": "ListItem",
                "position": 3,
                "item": {
                  "@id": "https://hype.day/aboutus",
                  "name": "About Us"
                }
              }
            ]
          }
        ]
      }`,
  };
}

function addVideoJsonLd() {
  return {
    __html: `{
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "How to build communtiy for your web3 campaigns using Hype.day",
      "description": "How to build communtiy for your web3 projects: Using Hype.day to run your NFT giveaway, access list, raffles and events",
      "thumbnailUrl": [
        "https://hype.day/twitterimg.png",
        "https://i.ytimg.com/vi/NR5m8f9Roh8/maxresdefault.jpg"
      ],
      "uploadDate": "2022-07-12",
      "duration": "PT6M23S",
      "contentUrl": "https://www.youtube.com/watch?v=NR5m8f9Roh8",
      "embedUrl": "https://www.youtube.com/embed/NR5m8f9Roh8",
      "interactionStatistic": {
        "@type": "InteractionCounter",
        "interactionType": { "@type": "WatchAction" },
        "userInteractionCount": 64
      },
      "regionsAllowed": "AD,AE,AF,AG,AI,AL,AM,AO,AQ,AR,AS,AT,AU,AW,AX,AZ,BA,BB,BD,BE,BF,BG,BH,BI,BJ,BL,BM,BN,BO,BQ,BR,BS,BT,BV,BW,BY,BZ,CA,CC,CD,CF,CG,CH,CI,CK,CL,CM,CN,CO,CR,CU,CV,CW,CX,CY,CZ,DE,DJ,DK,DM,DO,DZ,EC,EE,EG,EH,ER,ES,ET,FI,FJ,FK,FM,FO,FR,GA,GB,GD,GE,GF,GG,GH,GI,GL,GM,GN,GP,GQ,GR,GS,GT,GU,GW,GY,HK,HM,HN,HR,HT,HU,ID,IE,IL,IM,IN,IO,IQ,IR,IS,IT,JE,JM,JO,JP,KE,KG,KH,KI,KM,KN,KP,KR,KW,KY,KZ,LA,LB,LC,LI,LK,LR,LS,LT,LU,LV,LY,MA,MC,MD,ME,MF,MG,MH,MK,ML,MM,MN,MO,MP,MQ,MR,MS,MT,MU,MV,MW,MX,MY,MZ,NA,NC,NE,NF,NG,NI,NL,NO,NP,NR,NU,NZ,OM,PA,PE,PF,PG,PH,PK,PL,PM,PN,PR,PS,PT,PW,PY,QA,RE,RO,RS,RU,RW,SA,SB,SC,SD,SE,SG,SH,SI,SJ,SK,SL,SM,SN,SO,SR,SS,ST,SV,SX,SY,SZ,TC,TD,TF,TG,TH,TJ,TK,TL,TM,TN,TO,TR,TT,TV,TW,TZ,UA,UG,UM,US,UY,UZ,VA,VC,VE,VG,VI,VN,VU,WF,WS,YE,YT,ZA,ZM,ZW"
    }`,
  };
}

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>{SITE_TITLE}</title>
        <meta
          name="description"
          content="Web3 community building with high-quality access lists for Solana, Flow, Avalanche, Polygon, Klaytn and Ethereum projects, NFT giveaways and raffles; all free"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" key="og:title" content={SITE_TITLE} />
        <meta
          property="og:description"
          key="og:description"
          content="Web3 community building with high-quality access lists for Solana, Flow, Avalanche, Polygon, Klaytn and Ethereum projects, NFT giveaways and raffles; all free"
        />
        <meta
          property="og:image"
          key="og:image"
          content="https://hype.day/twitterimg.png"
        />
        <meta property="og:url" key="og:url" content="https://hype.day/" />
        <meta property="og:site_name" content="Hype.day" />
        <meta
          name="twitter:card"
          key="twitter:card"
          content="summary_large_image"
        />
        <meta name="twitter:site" key="twitter:site" content="@hypedotday" />
        <meta
          name="twitter:image:alt"
          content="Build HYPE for your Solana, Flow, Avalanche, Polygon, Klaytn and Ethereum projects, for free.
          Hype.day is the easiest way to build high-quality access lists for your web3 project's mints, giveaways, and raffles. Check out a sample campaign or peep the feature set below."
        />
        <meta
          name="google-site-verification"
          content="ny2b9ZZGw-bFdKNADDkL48U1nPCLrxrLrutRAxfoGA0"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={addWebsiteJsonLd()}
          key="website-jsonld"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={addVideoJsonLd()}
          key="video-jsonld"
        />
      </Head>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${CAPTCHA_KEY}`}
        strategy="afterInteractive"
      />
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </AuthProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
