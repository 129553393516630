import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  onYes: () => void;
  onOthers: () => void;
}

export default function SolanaLoginModal({
  isOpen,
  onClose,
  onYes,
  onOthers,
}: LoginModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Open in Phantom Mobile App?</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Button
            colorScheme="blue"
            mr={4}
            onClick={() => {
              onClose();
              onYes();
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              onClose();
              onOthers();
            }}
          >
            Other ways to connect
          </Button>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
