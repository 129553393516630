import {
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import useEvmLogin from "~/hooks/useEvmLogin";
import useFlowLogin from "~/hooks/useFlowLogin";
import useSolanaLogin from "~/hooks/useSolanaLogin";
import EmailModal from "./EmailModal";
import api from "~/core/api";
import { showError } from "~/core/helpers";
import { toast } from "~/core/toast";

export default function GetStartedButton(props: ButtonProps) {
  const { evmLogin, evmLoading, evmElement } = useEvmLogin();
  const { flowLogin, flowLoading } = useFlowLogin();
  const { solanaLogin, solanaLoading, solanaElement } = useSolanaLogin();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const loginWithDiscord = async () => {
    try {
      toast({
        description: "Redirecting to Discord...",
        status: "info",
        duration: 10000,
      });
      const url = await api.loginWithDiscord();
      window.location.replace(url);
    } catch (err) {
      console.error(err);
      showError(err);
    }
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          bg={"green.400"}
          color={"gray.50"}
          rounded={"full"}
          px={4}
          _hover={{
            bg: "green.500",
          }}
          fontWeight={"400"}
          isLoading={evmLoading || flowLoading || solanaLoading}
          size="sm"
          {...props}
        >
          Login / Register
        </MenuButton>
        <MenuList zIndex={2}>
          <MenuItem onClick={onOpen}>Email</MenuItem>
          <MenuItem onClick={loginWithDiscord}>Discord</MenuItem>
          <MenuItem onClick={() => evmLogin("login", "ethereum")}>
            Ethereum
          </MenuItem>
          <MenuItem onClick={() => solanaLogin("login")}>Solana</MenuItem>
          <MenuItem onClick={() => flowLogin("login")}>Flow</MenuItem>
          <MenuItem onClick={() => evmLogin("login", "polygon")}>
            Polygon
          </MenuItem>
          <MenuItem onClick={() => evmLogin("login", "avalanche")}>
            Avalanche
          </MenuItem>
          <MenuItem onClick={() => evmLogin("login", "klaytn")}>
            Klaytn
          </MenuItem>
        </MenuList>
      </Menu>
      {solanaElement}
      {evmElement}
      <EmailModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
