import { Box, Flex } from "@chakra-ui/react";
import Navbar from "./Navbar";
import FeedbackButton from "./FeedbackButton";
import Footer from "./Footer";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <Flex direction={"column"} minH="100vh">
      <Navbar />
      <Box marginBottom={12}>{children}</Box>
      <Box mt={"auto"}>
        <Footer />
      </Box>
      <FeedbackButton />
    </Flex>
  );
}
