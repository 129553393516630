import {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as Sentry from "@sentry/nextjs";
import {
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithCustomToken,
  signOut,
  User,
} from "firebase/auth";
import {
  doc,
  DocumentData,
  getDoc,
  getFirestore,
} from "firebase/firestore/lite";
import { UserDocument } from "~/core/types";
import { app, auth } from "~/core/initFirebase";
import api from "~/core/api";
import { captureException, captureMessage } from "@sentry/nextjs";
import { showError } from "~/core/helpers";
import { useRouter } from "next/router";
import { toast } from "~/core/toast";

interface AuthContextType {
  loadingInitial: boolean;
  user?: User;
  userData?: UserDocument;
  discordData?: DocumentData;
  twitterData?: DocumentData;
  disconnectWallet: MutableRefObject<(() => void) | undefined>;
  clearTwitterData: () => void;
  clearDiscordData: () => void;
  logout: () => void;
  getAllData: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const db = getFirestore(app);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User>();
  const [userData, setUserData] = useState<UserDocument>();
  const [discordData, setDiscordData] = useState<DocumentData>();
  const [twitterData, setTwitterData] = useState<DocumentData>();
  const [loadingInitial, setLoadingInitial] = useState(true);
  const disconnectWallet = useRef<() => void>();
  const isLoggingIn = useRef(false);
  const router = useRouter();

  const getUserData = useCallback(() => {
    if (!user?.uid) return;

    getDoc(doc(db, "users", user.uid))
      .then((doc) => {
        setUserData(doc.data() as UserDocument);
        Sentry.setUser({ id: user.uid, wallets: doc.data()?.wallets });
      })
      .catch((error) => {
        captureMessage("Error fetching user's data in auth.context.tsx");
        captureException(error);
        showError(error, true);
      });
  }, [user]);

  const getDiscordData = useCallback(() => {
    if (!user?.uid) return;

    getDoc(doc(db, "discord", user.uid))
      .then((doc) => {
        setDiscordData(doc.data());
      })
      .catch((error) => {
        captureMessage(
          "Error fetching user's discord data in auth.context.tsx"
        );
        captureException(error);
        showError(error, true);
      });
  }, [user]);

  const getTwitterData = useCallback(() => {
    if (!user?.uid) return;

    getDoc(doc(db, "twitter", user.uid))
      .then((doc) => {
        setTwitterData(doc.data());
      })
      .catch((error) => {
        captureMessage(
          "Error fetching user's twitter data in auth.context.tsx"
        );
        captureException(error);
        showError(error, true);
      });
  }, [user]);

  const getAllData = useCallback(() => {
    getUserData();
    getDiscordData();
    getTwitterData();
  }, [getUserData, getDiscordData, getTwitterData]);

  useEffect(() => {
    const { fbToken: token, email, otp, ecardId } = router.query;

    if (
      email &&
      typeof email === "string" &&
      otp &&
      typeof otp === "string" &&
      ecardId &&
      typeof ecardId === "string" &&
      !isLoggingIn.current
    ) {
      isLoggingIn.current = true;
      const toastId = toast({
        title: "Preparing...",
        description: "Please wait...",
        status: "info",
        isClosable: false,
      });

      api
        .verifyEmail(email, otp)
        .then((token) => signInWithCustomToken(auth, token))
        .then(() => {
          router.push(`/greeting-cards/profile?cardId=${ecardId}`, undefined, {
            shallow: true,
          });
        })
        .catch((err) => {
          console.error(err);
          showError(err);
        })
        .finally(() => {
          toast.close(toastId);
          isLoggingIn.current = false;
        });
    }

    if (token && typeof token === "string") {
      signInWithCustomToken(auth, token)
        .then(() => {
          router.push("/");
          toast({
            title: "Success",
            description: "Successfully logged in.",
          });
        })
        .catch((err) => {
          console.error(err);
          showError(err);
        });
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        user.getIdToken().then((token) => api.setToken(token));
        // exclude magic link logins, which are e-card recipients
      }
      setLoadingInitial(false);
    });

    return unsubscribe;
  }, [router]);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((token) => api.setToken(token));
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!user?.uid) return;
    getAllData();
  }, [user, getAllData]);

  const logout = useCallback(async () => {
    try {
      await signOut(auth);
      setUser(undefined);
      setDiscordData(undefined);
      setTwitterData(undefined);
      setUserData(undefined);
      Sentry.setUser(null);

      if (typeof disconnectWallet.current === "function")
        disconnectWallet.current();
      disconnectWallet.current = undefined;
    } catch (err) {
      console.error(err);
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      loadingInitial,
      user,
      userData,
      discordData,
      twitterData,
      disconnectWallet,
      clearTwitterData: () => setTwitterData(undefined),
      clearDiscordData: () => setDiscordData(undefined),
      logout,
      getAllData,
    }),
    [
      loadingInitial,
      user,
      userData,
      discordData,
      twitterData,
      logout,
      getAllData,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

export default function useAuthContext() {
  return useContext(AuthContext);
}
