import { createStandaloneToast } from "@chakra-ui/toast";
import theme from "./theme";

export const { ToastContainer, toast } = createStandaloneToast({
  theme,
  defaultOptions: {
    status: "success",
    duration: 5000,
    position: "top",
    isClosable: true,
  },
});
