import { AccountProofData } from "@onflow/fcl";
import { signInWithCustomToken } from "firebase/auth";
import { useCallback, useState } from "react";
import useAuthContext from "~/context/auth.context";
import api from "~/core/api";
import { showError } from "~/core/helpers";
import { auth } from "~/core/initFirebase";
import { toast } from "~/core/toast";

export default function useFlowLogin() {
  const [loading, setIsLoading] = useState(false);
  const { disconnectWallet, getAllData } = useAuthContext();
  const chain = "flow";

  const login = useCallback(
    async (mode: "login" | "add" = "login") => {
      let fcl;
      try {
        setIsLoading(true);
        fcl = (await import("~/core/fcl")).default;

        const result: any = await fcl.logIn();
        const accountProof: undefined | { data: AccountProofData } =
          result.services?.find(
            (service: any) => service.type === "account-proof"
          );

        if (!accountProof) {
          throw new Error(
            "Please make sure you signed the message in your wallet."
          );
        }
        if (mode === "add") {
          // we don't need this for flow but we use it to make sure that
          // user has only one wallet in this chain with this call
          await api.addWallet({ chain, address: result.addr });
          await api.verifyWallet({ chain, ...accountProof.data });

          fcl.unauthenticate();
          getAllData();
          toast({
            title: "Success!",
            description: "Flow wallet successfully saved.",
          });
        } else {
          await api.login({ chain, ...accountProof.data });
          const { token } = await api.verify({ ...accountProof?.data, chain });
          await signInWithCustomToken(auth, token);
          disconnectWallet.current = fcl.unauthenticate;

          toast({
            title: "Success!",
            description: "Successfully logged in with Flow.",
          });
        }
      } catch (err: any) {
        fcl?.unauthenticate();
        if (err.message !== "User rejected the request.") showError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [getAllData, disconnectWallet]
  );

  return { flowLogin: login, flowLoading: loading, flowComponent: null };
}
