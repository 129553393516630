import { SupportedChains } from "./types";

export const ROUTES = {
  notFound: "/404",
  homepage: "/",
  publicProjects: "/explore",
  projectBySlug: "/p/:slug",
  profile: "/profile",
  projects: "/created-projects",
  projectById: "/projects/:projectId",
  create: "/projects/create",
  creatorDashboard: "/creator-dashboard",
  edit: "/projects/:projectId/edit",
  joinedProjects: "/joined-projects",
  aboutUs: "/about-us",
  twitterCampaigns: "/twitter-campaigns",
  twitterCampaignsCreate: "/twitter-campaigns/create",
};

export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const IMAGE_PREFIX =
  "https://storage.googleapis.com/" +
  (process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
    ? "preparty-f79e2.appspot.com/"
    : "hype-testing.appspot.com/");
export const CAPTCHA_KEY = process.env.NEXT_PUBLIC_CAPTCHA_KEY;

const EVM_REGEX = new RegExp(/^\d*(\.\d{0,18})?$/);
export const evmChains = ["ethereum", "polygon", "avalanche", "klaytn"];

export const GOOGLE_FONT_APIKEY = "AIzaSyDQRXxXYN0sE70TXYQks5j3Kg5cGRQFWds";

export const SUPPORTED_CHAINS: {
  [key in SupportedChains]: {
    name: string;
    value: SupportedChains;
    currency: string;
    regex: RegExp;
  };
} = {
  ethereum: {
    name: "Ethereum",
    value: "ethereum",
    currency: "ETH",
    regex: EVM_REGEX,
  },
  polygon: {
    name: "Polygon",
    value: "polygon",
    currency: "MATIC",
    regex: EVM_REGEX,
  },
  avalanche: {
    name: "Avalanche C-Chain",
    value: "avalanche",
    currency: "AVAX",
    regex: EVM_REGEX,
  },
  klaytn: {
    name: "Klaytn",
    value: "klaytn",
    currency: "KLAY",
    regex: EVM_REGEX,
  },
  solana: {
    name: "Solana",
    value: "solana",
    currency: "SOL",
    regex: new RegExp(/^\d*(\.\d{0,9})?$/),
  },
  flow: {
    name: "Flow",
    value: "flow",
    currency: "FLOW",
    regex: new RegExp(/^\d*(\.\d{0,8})?$/),
  },
};

export const HYPE_BOT_LINK =
  "https://discord.com/api/oauth2/authorize?client_id=1019008534345695332&scope=applications.commands%20bot";

export const localStorageKeys = {
  ecardRefId: "ecard-ref-id",
};

export const ecardPrices = {
  standard: 9.99,
  discounted: 0.99,
};
