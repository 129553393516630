import { useCallback, useRef, useState } from "react";
import dynamic from "next/dynamic";
import useAuthContext from "~/context/auth.context";
import SolanaLoginModal from "~/components/SolanaLoginModal";
import { useDisclosure } from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { showError } from "~/core/helpers";
import useLogEvent from "./useLogEvent";
import { useRouter } from "next/router";

const SolanaWrapper = dynamic(() => import("~/components/SolanaWrapper"), {
  ssr: false,
});

export default function useSolanaLogin() {
  const [loading, setIsLoading] = useState(false);
  const [loadComponent, setLoadComponent] = useState(false);
  const modeRef = useRef<"login" | "add">("login");
  const { disconnectWallet, getAllData } = useAuthContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const logEvent = useLogEvent();
  const solanaModalRef = useRef<{
    showModal: () => void;
  }>();
  const router = useRouter();

  const login = useCallback(async (mode: "login" | "add" = "login") => {
    setIsLoading(true);
    setLoadComponent(true);
    modeRef.current = mode;
    // <LoginSolana> may be still loading so check if it's there
    const interval = setInterval(() => {
      if (solanaModalRef.current) {
        solanaModalRef.current.showModal();
        setIsLoading(false);
        clearInterval(interval);
      }
    }, 50);
  }, []);

  const checkIsMobile = useCallback(
    (mode: "login" | "add" = "login") => {
      const { phantomapp, mode: modeQuery } = router.query;
      const selectedMode = (modeQuery as "login" | "add" | undefined) || mode;
      modeRef.current = selectedMode;

      if (isMobile && !phantomapp) {
        return onOpen();
      }
      login(selectedMode);
    },
    [login, onOpen, router]
  );

  const openSolanaDeeplink = useCallback(
    async (mode: "login" | "add" = "login") => {
      logEvent("selected_solana_deeplink", {
        path: window.location.pathname,
      });

      try {
        const params = new URLSearchParams();
        params.append("mode", mode);
        params.append("phantomapp", "1");
        params.append("ref", "hypeday");
        const phantomLink =
          "https://phantom.app/ul/browse/" +
          encodeURIComponent(window.location.href + "?" + params.toString());
        window.open(phantomLink, "_blank");
      } catch (err: any) {
        console.error(err);
        showError(err);
      }
    },
    [logEvent]
  );

  const element = (
    <>
      <SolanaLoginModal
        isOpen={isOpen}
        onClose={onClose}
        onYes={() => openSolanaDeeplink(modeRef.current)}
        onOthers={() => {
          logEvent("selected_solana_standardpath", {
            path: window.location.pathname,
          });
          login(modeRef.current);
        }}
      />
      {loadComponent ? (
        <SolanaWrapper
          forwardedRef={solanaModalRef}
          disconnectWallet={disconnectWallet}
          getAllData={getAllData}
          setIsLoading={setIsLoading}
          modeRef={modeRef}
        />
      ) : null}
    </>
  );

  return {
    solanaLogin: checkIsMobile,
    solanaLoading: loading,
    solanaElement: element,
  };
}
