import { useDisclosure } from "@chakra-ui/react";
import { signInWithCustomToken } from "firebase/auth";
import { useCallback, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import NotificationModal from "~/components/NotificationModal";
import useAuthContext from "~/context/auth.context";
import api from "~/core/api";
import { SUPPORTED_CHAINS } from "~/core/constants";
import { showError } from "~/core/helpers";
import { auth } from "~/core/initFirebase";
import { toast } from "~/core/toast";
import { EvmChains } from "~/core/types";

export default function useEvmLogin() {
  const [loading, setIsLoading] = useState(false);
  const { disconnectWallet, getAllData } = useAuthContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modRef = useRef<"login" | "add">("login");
  const chainRef = useRef<EvmChains>("ethereum");

  const login = useCallback(
    async (mode: "login" | "add" = "login", chain: EvmChains = "ethereum") => {
      try {
        setIsLoading(true);
        const [
          { default: Web3Modal },
          { default: WalletConnectProvider },
          { default: CoinbaseWalletSDK },
          { Web3Provider },
          { getBloctoProvider },
        ] = await Promise.all([
          import("web3modal"),
          import("@walletconnect/web3-provider"),
          import("@coinbase/wallet-sdk"),
          import("@ethersproject/providers"),
          import("../core/blocto"),
        ]);

        const providerOptions: { [key: string]: any } = {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              rpc: {
                "1": process.env.NEXT_PUBLIC_ALCHEMY_ETH_RPC,
              },
            },
          },
          coinbasewallet: {
            package: CoinbaseWalletSDK,
            options: {
              appName: "Preparty",
              rpc: process.env.NEXT_PUBLIC_ALCHEMY_ETH_RPC,
              chainId: 1,
              darkMode: false,
            },
          },
        };

        const bloctoProvider = getBloctoProvider(chain);
        if (bloctoProvider) {
          providerOptions["custom-blocto"] = bloctoProvider;
        }

        const web3Modal = new Web3Modal({
          network: "mainnet",
          cacheProvider: false,
          providerOptions,
        });

        const instance = await web3Modal.connect();
        const provider = new Web3Provider(instance);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const { nonce } = await (mode === "add"
          ? api.addWallet({ chain, address })
          : api.login({ chain, address }));
        const signature = await signer.signMessage(nonce);

        if (mode === "add") {
          await api.verifyWallet({
            address,
            signature,
            chain,
          });

          getAllData();
          toast({
            title: "Success!",
            description: `${chain.toUpperCase()} wallet successfully saved.`,
          });
        } else {
          const { token } = await api.verify({ chain, address, signature });
          await signInWithCustomToken(auth, token);
          // there is no programatic disconnect functionality in EVM wallets
          disconnectWallet.current = undefined;

          toast({
            title: "Success!",
            description: `Successfully logged in with ${SUPPORTED_CHAINS[chain].name}.`,
          });
        }
      } catch (err: any) {
        if (
          [
            "User Rejected",
            "User closed modal",
            "MetaMask Personal Message Signature: User denied message signature.",
            "MetaMask Message Signature: User denied message signature.",
            "Modal closed by user",
            "User denied account authorization",
          ].includes(err.message ?? err)
        ) {
          return;
        }
        if (err.message.includes("user rejected signing")) {
          return;
        }

        console.error(err);
        showError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [getAllData, disconnectWallet]
  );

  const checkIsMobile = useCallback(
    (mode: "login" | "add" = "login", chain: EvmChains = "ethereum") => {
      if (isMobile) {
        modRef.current = mode;
        chainRef.current = chain;
        return onOpen();
      }
      login(mode, chain);
    },
    [onOpen, login]
  );

  const element = (
    <NotificationModal
      isOpen={isOpen}
      onClose={onClose}
      message={
        "MetaMask mobile app users, please select WalletConnect in the next step. If it doesn't connect on your first try, please come back to this page and try connecting again. It sometimes takes couple tries to connect successfully."
      }
      successFunction={() => login(modRef.current, chainRef.current)}
    />
  );

  return {
    evmLogin: checkIsMobile,
    evmLoading: loading,
    evmElement: element,
  };
}
